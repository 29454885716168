//import "./css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import  "./css/table.css";
import "rsuite/dist/rsuite.min.css";
import  "../../src/gitprofile/dist/style.css";

export default function MyApp({ Component, pageProps }) {
    return <Component {...pageProps} />;
};

